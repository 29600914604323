import { type ReactNode, useCallback } from 'react';
import { createContext, useContext, useMemo } from 'react';
import { z } from 'zod';

import type { ApiUser } from '@/api/auth/user';
import type { AsaTaskInfo } from '@/client/features/asa/task-info';
import type { Campaign } from '@/client/features/signup/api';
import { useRouter } from '@/hooks/use-compatible-router';
import { useUser } from '@/hooks/use-user';
import { OAuthLinkProvider } from '@/shared/oauth/providers';
import { queryParamSchema } from '@/shared/utils/zod';

export interface Coupon {
  id: string;
  name: null | string;
  duration: 'forever' | 'once' | 'repeating';
  amountOff: null | number;
  percentOff: null | number;
  valid: boolean;
}

export type CheckoutVariant = 'minecraft' | 'roblox' | 'unlimited' | 'summer';

export interface AuthContext {
  asaTaskInfo?: AsaTaskInfo; // Task info if they came from ASA, so we can call back when the given quest is complete.
  campaign?: Campaign;
  coupon?: Coupon;
  email?: string;
  fbclid?: string;
  firstName?: string;
  intercomId?: string;
  inviteCode?: string;
  lastName?: string;
  linkingProvider?: OAuthLinkProvider; // When we are linking a social provider we hide that provider's login button to avoid confusion
  mobileDisplay: boolean;
  phone?: string;
  redirect?: string; // post-authentication redirect url
  refetch: () => Promise<void>; // centralized refetch function
}

export const authContextQueryParamsSchema = z
  .object({
    link: queryParamSchema(z.string()),
    redirect: queryParamSchema(z.string()),
  })
  .catch({});

export type AuthContextQueryParams = z.infer<typeof authContextQueryParamsSchema>;

declare const pageAndAuthQueryParamsCollide: unique symbol;

type MitigateQueryParamCollisions<PageParams, ComponentParams, ComponentProps> = ComponentProps &
  ({
    pageQueryParams: PageParams;
  } & ([keyof PageParams & keyof ComponentParams] extends [never]
    ? unknown
    : {
        [pageAndAuthQueryParamsCollide]: Record<
          keyof PageParams & keyof ComponentParams,
          typeof pageAndAuthQueryParamsCollide
        >;
      }));

type ProviderProps<T> = MitigateQueryParamCollisions<
  T,
  AuthContextQueryParams,
  {
    asaTaskInfo?: AsaTaskInfo;
    campaign?: Campaign;
    children: ReactNode;
    email?: string;
    fbclid?: string;
    firstName?: string;
    intercomId?: string;
    inviteCode?: string;
    lastName?: string;
    mobileDisplay: boolean;
    phone?: string;
    /**
     * redirect if/when the user becomes available. If provided as a string, the 'redirect' query params takes precedence
     * when present.
     */
    redirectTo?: string | ((user: ApiUser, queryRedirect: undefined | string) => undefined | null | string);
  }
>;

const Context = createContext<AuthContext | undefined>(undefined);

export const AuthProvider = <T,>({
  asaTaskInfo,
  campaign,
  children,
  email,
  fbclid,
  firstName,
  intercomId,
  inviteCode,
  lastName,
  mobileDisplay,
  phone,
  redirectTo,
}: ProviderProps<T>) => {
  const router = useRouter();

  const routerQuery = router.query;

  const { link, redirect: queryRedirect } = useMemo(() => {
    const parsedQueryParams = authContextQueryParamsSchema.safeParse(routerQuery);
    return parsedQueryParams.success ? parsedQueryParams.data : {};
  }, [routerQuery]);

  const userRedirectTo = useCallback(
    (user: ApiUser) => {
      if (!redirectTo) {
        return;
      }

      return typeof redirectTo === 'string' ? (queryRedirect ?? redirectTo) : redirectTo(user, queryRedirect);
    },
    [queryRedirect, redirectTo]
  );

  const { refetch } = useUser({ redirectTo: userRedirectTo });

  const authContext = useMemo(() => {
    const context: AuthContext = {
      mobileDisplay,
      refetch,
    };

    if (asaTaskInfo) {
      context.asaTaskInfo = asaTaskInfo;
    }

    if (campaign) {
      context.campaign = campaign;
    }

    if (email) {
      context.email = email;
    }

    if (fbclid) {
      context.fbclid = fbclid;
    }

    if (firstName) {
      context.firstName = firstName;
    }

    if (intercomId) {
      context.intercomId = intercomId;
    }

    if (inviteCode) {
      context.inviteCode = inviteCode;
    }

    if (lastName) {
      context.lastName = lastName;
    }

    if (typeof link === 'string' && Object.values(OAuthLinkProvider).includes(link as OAuthLinkProvider)) {
      context.linkingProvider = link as OAuthLinkProvider;
    }

    if (phone) {
      context.phone = phone;
    }

    return context;
  }, [
    asaTaskInfo,
    campaign,
    email,
    fbclid,
    firstName,
    intercomId,
    inviteCode,
    lastName,
    link,
    mobileDisplay,
    phone,
    refetch,
  ]);

  return <Context.Provider value={authContext}>{children}</Context.Provider>;
};

export const useAuthContext = (): AuthContext => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('"useAuthContext" must be called inside "AuthProvider"');
  }

  return context;
};
