import { clsx } from 'clsx';
import RightArrow from 'public/icons/arrow-right.svg';
import CircleCheck from 'public/icons/circle-check.svg';
import React from 'react';

import { Spinner } from '@/client/design-system/components/spinner';

interface ResendButtonProps {
  loading?: boolean;
  disabled?: boolean;
  success?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ResendButton = ({ loading, disabled, success, onClick }: ResendButtonProps) => (
  <button
    type='button'
    onClick={onClick}
    className={clsx(
      'flex items-center gap-0.5 text-small-plus',
      success ? 'pointer-events-none text-positive' : disabled ? 'pointer-events-none text-blue/40' : 'text-blue'
    )}
  >
    <span>{success ? 'Sent' : 'Resend'}</span>
    {success ? <CircleCheck className='size-4' /> : loading ? <Spinner size={16} /> : <RightArrow className='size-4' />}
  </button>
);
